import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { Link, useFetcher } from '@remix-run/react'
import { Trans, useTranslation } from 'react-i18next'
import { AuthenticityTokenInput } from 'remix-utils/csrf/react'
import { HoneypotInputs } from 'remix-utils/honeypot/react'
import { z } from 'zod'

import { Button, ButtonProps } from '@biogroup/ui/button'
import { CheckboxField } from '@biogroup/ui/checkbox-field'
import { ErrorList } from '@biogroup/ui/error-list'

import { action as createAccountAction } from '#app/routes/_auth+/create-account.ts'

const ConsentFormSchema = z.object({
  acceptTerms: z.boolean().default(false),
})

export function ConsentFormSubmit({ isLoading, ...props }: ButtonProps) {
  const { t } = useTranslation()
  return (
    <Button
      type="submit"
      className="mt-4 w-fit self-center"
      isLoading={isLoading}
      disabled={isLoading}
      {...props}
    >
      {t('cta.createAccount')}
    </Button>
  )
}

export function ConsentForm({
  children,
  renderSubmit,
}: {
  children?: React.ReactNode
  renderSubmit?: (isLoading: boolean) => React.ReactNode
}) {
  const { t } = useTranslation()
  const fetcher = useFetcher<typeof createAccountAction>()

  const [form, fields] = useForm({
    id: 'consent-form',
    constraint: getZodConstraint(ConsentFormSchema),
    lastResult: fetcher.data?.result,
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: ConsentFormSchema })
    },
    shouldRevalidate: 'onInput',
  })

  return (
    <fetcher.Form
      method="POST"
      action="/create-account"
      className="flex w-full flex-col gap-y-4"
      {...getFormProps(form)}
    >
      <HoneypotInputs />
      <AuthenticityTokenInput />
      <CheckboxField
        className="mx-auto text-center"
        labelProps={{
          htmlFor: fields.acceptTerms.id,
          children: (
            <Trans
              i18nKey={'form.acceptTerms.label'}
              components={{
                Link: (
                  <Link
                    to="/user-agreement"
                    target="_blank"
                    rel="noopener nofollower"
                    className="link text-foreground"
                  />
                ),
              }}
            />
          ),
        }}
        buttonProps={{
          ...getInputProps(fields.acceptTerms, {
            type: 'checkbox',
          }),
          'aria-label': t('form.acceptTerms.label.a11y'),
        }}
        errors={fields.acceptTerms.errors}
      />
      <ErrorList className="mx-auto text-center" errors={form.errors} />
      {renderSubmit ? renderSubmit(fetcher.state !== 'idle') : null}
      {children}
    </fetcher.Form>
  )
}
